import React from 'react';
import styles from '../../index.module.css';
import cn from 'classnames';
import {useTranslation} from 'react-i18next';

const TableViewCounter = (props: any) => {
  const { count, selectedRows, tableId, simpleTable, isCardTable, readonly } = props;
  const {t} = useTranslation();
  return (
    <>
      {count.openCount || count.closedCount ? (
        <h3
          className={cn(styles.text, styles.regCounter)}
        >{`${t('total')} ${count.count} ${t("requests")}, ${t("open")} ${count.openCount}, ${t("closedReqs")} ${count.closedCount}`}</h3>
      ) : (
        <h3
          className={cn(styles.text, {
            [styles.regCounter]: !simpleTable && !isCardTable,
          })}
        >
          {selectedRows?.[tableId]?.length ? (
            <span className={styles.textCell}>
              {t('selected')} {selectedRows?.[tableId]?.length}
            </span>
          ) : (
            ''
          )}
          {`${t('total')} ${count.count} ${t("records")}`}
        </h3>
      )}
    </>
  );
};

export default TableViewCounter;
