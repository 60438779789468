import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Collapse, Row, Tooltip, Typography } from 'antd';
import { chunk, isBoolean } from 'lodash';
import cn from 'classnames';
import { InfoCircleOutlined } from '@ant-design/icons';
import AccordionIcon from 'components/icons/AccordionIcon';
import EntityEditorContext from 'pages/entityEditor/EntityEditorContext/EntityEditorContext';

import styles from 'pages/entityEditor/Tabs/EntityEditorDetails.module.css';
import stylesEntityList from 'pages/entityList/EntityList.module.css';
import stylesHintText from '../controls/Checkbox/InputCheckbox.module.css';
import ServerAPI from 'integration/ServerAPI';
import ServerError from 'integration/ServerError';

import {
  getLegendHint,
  getLegendName,
  isActiveComponent,
  isHiddenComponent,
  isTransitionComponent,
} from './utils';
import ControlFactory from 'utils/ControlFactory';
import { formatEntity } from 'utils/FormatUtils';
import { AppState } from 'store/reducers';
import HintIcon from 'components/controls/SelectControl/HintIcon';
import TransitionButton from 'components/controls/SelectControl/TransitionButton';
import {
  OptionData,
  convertDataToType,
  valueToOption,
} from 'components/controls/SelectControl/SelectControl';

interface CardControlProps {
  factory: ControlFactory;
  component?: any;
  isChunked?: boolean;
  useKB?: string;
}

interface CardRowProps {
  factory: ControlFactory;
  rowData?: any;
  useKB?: string;
}

interface CardPanelProps {
  factory: ControlFactory;
  panelData?: any;
  isNew?: boolean;
  useKB?: string;
}

interface CardLayoutProps {
  factory?: ControlFactory;
  noCollapse?: boolean;
  isNew?: boolean;
  inModal?: boolean;
  useKB?: string;
}

export const CardControl: React.FC<CardControlProps> = ({
  factory,
  component,
  isChunked,
  useKB,
}) => {
  return factory.create(
    { component },
    isChunked,
    component.propName === 'EngineeringTechnicalRequestResponce' ? useKB : undefined
  );
};

export const CardRow: React.FC<CardRowProps> = ({ factory, rowData, useKB }) => {
  return (
    <Row gutter={16}>
      {rowData?.components?.map((component: any, k: number) => (
        <CardControl factory={factory} useKB={useKB} component={component} key={'control_' + k} />
      )) || null}
    </Row>
  );
};

export const CardPanelReadOnly: React.FC<CardPanelProps> = ({ factory, panelData, useKB }) => {
  const { form } = useContext(EntityEditorContext);
  const choiceLists = useSelector((state: AppState) => state.choiceLists);
  const hiddenTables = useSelector((state: AppState) => state.hiddenEntities.hiddenTables);

  const activeComponents: any[] = [];
  const disabledComponents: any[] = [];
  panelData?.componentsRows?.forEach((row: any) => {
    row?.components?.forEach((component: any) => {
      if (
        !component ||
        isHiddenComponent(component) ||
        hiddenTables.find((className) => component?.params.className === className)
      )
        return;

      if (isActiveComponent(component)) {
        activeComponents.push(component);
      } else {
        disabledComponents.push(component);
      }
    });
  });

  const chunkSize = Math.ceil(disabledComponents.length / 3);
  const chunkedComponents: any[][] =
    disabledComponents.length > 5 ? chunk(disabledComponents, chunkSize) : [disabledComponents];
  if (activeComponents.length) chunkedComponents.push(activeComponents);

  return (
    <div className={styles.readOnly}>
      {chunkedComponents.map((chunk: any) => {
        if (chunkedComponents.length < 3 && chunk.length < 3) {
          return chunk.map((component: any, k: number) => (
            <CardControl
              useKB={useKB}
              factory={factory}
              component={component}
              key={'control_' + k}
            />
          ));
        }

        const readonly: boolean = Boolean(
          chunk[0] && chunk[0].readonly && chunk[0].templateName !== 'attachmentList'
        );

        return (
          <div
            className={cn({
              [styles.readOnlyBlock]: readonly,
              [styles.activeItems]: !readonly,
              [styles.readOnlyBlockOne]: readonly && chunkedComponents.length === 1,
            })}
          >
            {chunk.map((component: any, k: number) => {
              if (!component) return null;

              if (isActiveComponent(component)) {
                return <CardControl factory={factory} component={component} key={'control_' + k} />;
              }

              if (!form) return null;

              if (component.templateName === 'loadData') {
                return (
                  <CardControl
                    factory={factory}
                    component={component}
                    isChunked={true}
                    key={'control_' + k}
                  />
                );
              }

              const formattedValue = formatEntity(
                form.getFieldValue(component.propName),
                component,
                choiceLists
              )?.value;
              const optValues: OptionData[] = [];

              if (isTransitionComponent(component)) {
                const choiceValues: any[] = convertDataToType(
                  component.propertyType,
                  Object.values(choiceLists[component.choiceName]?.choiceItems),
                  form.getFieldValue(component.propName)
                );
                optValues.push(...choiceValues.map(valueToOption));
              }
              return (
                <div style={{ display: 'flex' }} key={'typotext_' + k}>
                  <div className={styles.readOnlyLabel}>
                    {component.hint !== null ? (
                      <HintIcon hint={component.hint} cssClassTooltip={stylesEntityList.tooltip} />
                    ) : (
                      <></>
                    )}
                    <Typography.Text
                      className={styles.label}
                      ellipsis={{
                        tooltip: {
                          title: component.label,
                          children: component.label,
                          placement: 'leftBottom',
                          arrow: false,
                          autoAdjustOverflow: false,
                          color: 'var(--color-light)',
                          overlayClassName: stylesEntityList.tooltip,
                        },
                      }}
                    >
                      {component.label}
                    </Typography.Text>
                    <div>
                      {isTransitionComponent(component) && (
                        <TransitionButton
                          transition={true}
                          options={optValues}
                          value={form.getFieldValue(component.propName)}
                          isReadOnly={true}
                        />
                      )}
                    </div>
                  </div>
                  <div className={styles.readOnlyValue}>
                    {formattedValue === ''
                      ? '-'
                      : Array.isArray(formattedValue)
                      ? formattedValue.map(
                          (val: string, n: number) =>
                            `${val}${n !== formattedValue.length - 1 ? ', ' : ''}`
                        )
                      : formattedValue}
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export const CardPanel: React.FC<CardPanelProps> = (props) => {
  const { factory, panelData, isNew, useKB } = props;

  if (factory?.getConfig().params.readonlyCard[panelData.legend] === true && !isNew) {
    return <CardPanelReadOnly {...props} />;
  }

  return (
    <>
      {panelData?.componentsRows?.map((rowData: any, j: number) => (
        <CardRow useKB={useKB} factory={factory} rowData={rowData} key={'row_' + j} />
      )) || null}
      <div className={styles.hint}>{getLegendHint(panelData.legend)}</div>
    </>
  );
};

export const CardPanelWithBlocks: React.FC<CardPanelProps> = (props) => {
  const { panelData } = props;

  return (
    <>
      <div className={styles.panelWithBlocks}>
        <div className={styles.legend}>{getLegendName(panelData.legend)}</div>
        <CardPanel {...props} />
      </div>
    </>
  );
};

export const CardCollapse: React.FC<CardPanelProps> = (props) => {
  const { factory, panelData } = props;

  const hiddenTables = useSelector((state: AppState) => state.hiddenEntities.hiddenTables);

  if (
    panelData.componentsRows.length === 1 &&
    panelData.componentsRows[0].components.length === 1 &&
    hiddenTables.find(
      (className) => panelData.componentsRows[0].components[0].params.className === className
    )
  ) {
    return <></>;
  }

  if (factory?.getConfig().params.panelWithBlocks) {
    return <CardPanelWithBlocks {...props} />;
  }
  return (
    <Collapse
      className={styles.collapse}
      defaultActiveKey={!panelData?.closed ? 'panel' : undefined}
      expandIcon={({ isActive }) => <AccordionIcon active={isActive === true} />}
      ghost={true}
    >
      <Collapse.Panel header={getLegendName(panelData.legend)} key={'panel'}>
        <CardPanel {...props} />
      </Collapse.Panel>
    </Collapse>
  );
};

export const CardLayout: React.FC<CardLayoutProps> = (props) => {
  const { factory, noCollapse, isNew, inModal, useKB } = props;

  if (!factory) return null;

  const layoutConfig = factory.getConfig();
  const panels = layoutConfig?.panels;
  const openPanels = panels?.filter((panel: any) => !panel.hidden);

  if (!openPanels?.length) return null;

  const showCollapseAll: boolean | null = noCollapse
    ? false
    : isBoolean(layoutConfig.collapse)
    ? layoutConfig.collapse
    : !inModal && isBoolean(layoutConfig.cardCollapse)
    ? layoutConfig.cardCollapse
    : inModal && isBoolean(layoutConfig.modalCollapse)
    ? layoutConfig.modalCollapse
    : null;

  const showCollapseAuto: boolean = openPanels.length > 1;

  return (
    <>
      {openPanels.map((panelData: any, i: number) => {
        const panelProps = {
          factory,
          panelData,
          isNew,
          key: 'panel_' + i,
          useKB,
        };
        const showPanelCollapse: boolean = isBoolean(showCollapseAll)
          ? showCollapseAll
          : isBoolean(panelData.collapse)
          ? panelData.collapse
          : !inModal && isBoolean(panelData.cardCollapse)
          ? panelData.cardCollapse
          : inModal && isBoolean(panelData.modalCollapse)
          ? panelData.modalCollapse
          : showCollapseAuto;

        return (showPanelCollapse || factory?.getConfig().params.panelWithBlocks) &&
          panelProps.panelData.legend.length > 0 ? (
          <CardCollapse {...panelProps} />
        ) : (
          <CardPanel {...panelProps} />
        );
      })}
    </>
  );
};

export default CardLayout;
