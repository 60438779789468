import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';

import EngineeringTechnicalRequestIcon from 'components/icons/EngineeringTechnicalRequestIcon';
import ExportRegistryIcon from 'components/icons/ExportRegistryIcon';
import ExportETRAndMessage from 'components/icons/ExportETRAndMessage';
import EDRequestIcon from 'components/icons/EDRequestIcon';
import DownloadLogIcon from 'components/icons/case/DownloadLogIcon';
import EDIcon from 'components/icons/EDIcon';
import PlaneIcon from 'components/icons/PlaneIcon';
import TOIcon from 'components/icons/TOIcon';
import ExportTemplateIcon from 'components/icons/ExportTemplateIcon';
import CreateObjectIcon from 'components/icons/CreateObjectIcon';
import ListExportIcon from 'components/icons/ListExportIcon';
import LinkedZkPIcon from '../../../components/icons/case/LinkedZkPIcon';

import { UserData } from 'interfaces/UserData';
import { TableType, ToolActionType } from 'interfaces/Table';
import { TransitionType } from 'interfaces/CardTransition';

import modal from 'components/messages/modal';
import MiscUtils from 'utils/MiscUtils';
import composeLink from 'utils/composeLink';

import styles from '../index.module.css';

import EntityEditorContext from 'pages/entityEditor/EntityEditorContext/EntityEditorContext';
import { useTableViewTools } from 'components/TableView/hooks';
import { FormObjectData } from 'interfaces/Form';
import { AppState } from 'store/reducers';
import { ButtonTypesEnum } from 'utils/types';

interface EntityListButtonProps {
  name?: ToolActionType;
  title?: string;
  icon: string;
  fields?: any;
  needUpdate?: boolean;
  hidden?: boolean;
  addTools?: string[];
  removeTools?: string[];
  inboxName?: string;
  className: string;
  parentId?: string;
  parentClassName?: string;
  filters: any;
  filtersConfiguration: any;
  loading: any;
  isCardTable?: boolean;
  creationType?: TransitionType;
  addGuidItemsTable?: any;
  handleChangeVisibleButton?: any;
  tableType: TableType;
  tableId: string;
  refreshID: any;
  massReplaceTool?: any;
  sort: any;
  filtersForm: any;
  conditionId: any;
  fillCriteriaFrom: any;
  width: any;
  controller?: string;
  readonly: boolean;
  type: ButtonTypesEnum;
  isOldButton?: boolean;
  toolName?: string;
}

const ButtonIcon: React.FC<{ icon: string }> = React.memo((props) => {
  switch (props.icon) {
    case 'ETR':
      return <EngineeringTechnicalRequestIcon className={styles.iconButton} />;
    case 'export':
      return <ExportRegistryIcon className={styles.iconButton} />;
    case 'exportETRAndMessageOne':
    case 'exportETRAndMessageTwo':
    case 'exportETRAndMessageThree':
      return <ExportETRAndMessage className={styles.iconButton} />;
    case 'ED':
      return <EDRequestIcon className={styles.iconButton} />;
    case 'EDIcon':
      return <EDIcon className={styles.iconButton} />;
    case 'PlaneIcon':
      return <PlaneIcon className={styles.iconButton} />;
    case 'TOIcon':
      return <TOIcon className={styles.iconButton} />;
    case 'importObjects':
      return <DownloadLogIcon className={styles.iconButton} />;
    case 'exportTemplate':
      return <ExportTemplateIcon className={styles.iconButton} />;
    case 'createObject':
      return <CreateObjectIcon className={styles.iconButton} />;
    case 'listExport':
      return <ListExportIcon className={styles.iconButton} />;
    case 'LinkedZkPIcon':
      return <LinkedZkPIcon className={styles.iconButton} />;
    }
  return <></>;
});

const TableViewToolButton: React.FC<EntityListButtonProps> = (props) => {
  const {
    title,
    name = '',
    icon,
    fields,
    needUpdate,
    hidden = false,
    addTools = [],
    removeTools = [],
    inboxName,
    className,
    parentId,
    parentClassName,
    isCardTable,
    creationType,
    addGuidItemsTable = null,
    handleChangeVisibleButton,
    tableType,
    tableId,
    refreshID,
    massReplaceTool,
    filters,
    filtersConfiguration,
    loading,
    sort,
    filtersForm,
    conditionId,
    fillCriteriaFrom,
    width,
    readonly,
    type,
    controller = '',
    isOldButton,
    toolName = '',
  } = props;

  const history = useHistory();
  const { form, onValuesChange, onFormFinish } = useContext(EntityEditorContext);
  const userData: UserData = useSelector((state: any) => state.typeUser.userData);
  const selectedRows = useSelector((state: AppState) => state.selectedRows?.[tableId]);
  const editor = useSelector((state: AppState) => state.editor.disabledTableControls);
  const [disabled, setDisabled] = useState<boolean>(false);

  const {
    tableViewAttachTool,
    tableViewMassReplaceTool,
    tableViewAddTool,
    tableViewExportTool,
    tableViewExportMessagesTool,
    tableViewExportTemplateTool,
    tableViewImportTool,
  } = useTableViewTools({
    tableId,
    addGuidItemsTable,
    refreshID,
    cardForm: form,
    massReplaceTool,
    inboxName,
    className,
    isCardTable,
    parentClassName,
    parentId,
    filters,
    filtersConfiguration,
    loading,
    filtersForm,
    conditionId,
    fillCriteriaFrom,
    width,
  });

  const newLink = () =>
    composeLink({
      inbox: inboxName,
      className,
      id: 'new',
      parentClassName: isCardTable ? parentClassName : undefined,
      parentId: isCardTable ? parentId : undefined,
    });

  useEffect(() => {
    setDisabled(readonly);
  }, [readonly])
  const onClick = async (evt: any) => {
    setDisabled(true);
    // Конфигурация кнопок переходит на новую, более универсальную схему. Сейчас реализована поддержка как старых кнопок, так и новых
    if (!isOldButton) {
      switch (type) {
        case ButtonTypesEnum.CREATE:
          history.push(newLink());
          break;
      
        case ButtonTypesEnum.EXPORT:
          tableViewExportTool(sort, controller)
          break;

        case ButtonTypesEnum.EXPORT_BIRT:
          const toolId = `${controller}/${new Date().getTime()}`;
          await tableViewExportMessagesTool(evt, toolName, toolId);
          break;
  
        case ButtonTypesEnum.MODAL:
          switch (name) {
            case ToolActionType.ADD:
              if (isCardTable) await tableViewAddTool(TransitionType.MODAL);
              break;
  
            case ToolActionType.ATTACH:
              if (isCardTable && tableType === TableType.ARRAY && addGuidItemsTable) {
                await tableViewAttachTool();
              }
              break;
  
            case ToolActionType.IMPORT_OBJECTS:
            case ToolActionType.IMPORT_FILES:
              await tableViewImportTool();
              break;
          
            default:
              break;
          }
          break;
        default:
          break;
      }
    } else {
      if (isCardTable && (addTools?.length || removeTools?.length)) {
        handleChangeVisibleButton(addTools, removeTools);
      }

      switch (name) {
        case ToolActionType.ADD:
          if (isCardTable) await tableViewAddTool(creationType);
          break;
  
        case ToolActionType.ATTACH:
          if (isCardTable && tableType === TableType.ARRAY && addGuidItemsTable) {
            await tableViewAttachTool();
          }
          break;
  
        case ToolActionType.CREATE:
          history.push(newLink());
          break;
  
        case ToolActionType.GEN_ETR_RES_REP_1:
        case ToolActionType.GEN_ETR_RES_REP_2:
        case ToolActionType.GEN_ETR_RES_REP_3:
        case ToolActionType.GEN_ED_AND_RES_REP_1:
        case ToolActionType.GEN_ED_AND_RES_REP_2:
        case ToolActionType.GEN_ED_AND_RES_REP_3:
          const toolId = `${controller}/${new Date().getTime()}`;
          await tableViewExportMessagesTool(evt, name, toolId);
          break;
  
        case ToolActionType.EXPORT:
          await tableViewExportTool(sort);
          break;
  
        case ToolActionType.IMPORT_OBJECTS:
          await tableViewImportTool();
          break;
  
        case ToolActionType.GET_TEMPLATE:
          await tableViewExportTemplateTool();
          break;
  
        case ToolActionType.MASS_REPLACE:
          await tableViewMassReplaceTool();
          break;
  
        case ToolActionType.FIELD_CHANGE:
          if (!isCardTable) break;
          const fieldsValues: FormObjectData = {};
          const formValues: FormObjectData = form?.getFieldsValue(true) || {};
          Object.keys(fields).forEach((fieldKey: string) => {
            // Проверка заменяемых значений с текущеми на форме
            if (formValues.hasOwnProperty(fieldKey) && formValues[fieldKey] === fields[fieldKey])
              return;
  
            fieldsValues[fieldKey] = fields[fieldKey];
          });
          // Если нет заменяемых значений, выходим
          if (!Object.keys(fieldsValues).length) break;
  
          form?.setFieldsValue(fieldsValues);
          onValuesChange?.(fieldsValues);
  
          if (needUpdate && onFormFinish) {
            modal.confirmSave({
              onOk: () => {
                setTimeout(() => {
                  onFormFinish();
                }, 500);
              },
            });
          }
          break;
      }
    }
    setDisabled(false);
  };

  return !hidden ? (
    <Button
      onClick={onClick}
      htmlType='button'
      type='text'
      className={styles.button}
      disabled={disabled || (controller === ToolActionType.MASS_REPLACE && !selectedRows?.length) || ( inboxName!== undefined && editor[inboxName])}
    >
      <ButtonIcon icon={icon} />
      {MiscUtils.notNullString(title)}
    </Button>
  ) : (
    <></>
  );
};

export default React.memo(TableViewToolButton);
