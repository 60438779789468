import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const iconSvg = () => (
  <svg width='1.2rem' height='1.2rem' viewBox='0 0 38 40' xmlns='http://www.w3.org/2000/svg'>
    <path d='M22 13.894L38 24V28L22 22.948V33.668L28 37V40L19 38L10 40V37L16 33.666V22.946L0 28V24L16 13.894V3C16 2.20435 16.3161 1.44129 16.8787 0.87868C17.4413 0.316071 18.2044 0 19 0C19.7956 0 20.5587 0.316071 21.1213 0.87868C21.6839 1.44129 22 2.20435 22 3V13.894Z' />
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={iconSvg} {...iconProps} />;
};

export default CustomIcon;
